(function () {
  'use strict';

  class Milestones {
    constructor(MilestonesRest, $cookies, $rootScope) {
      this.MilestonesRest = MilestonesRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    milestonesIndex(milestones = {}, callback = angular.noop) {
      return this.MilestonesRest.milestones().get(milestones,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    milestonesCreate(milestones = {}, callback = angular.noop) {
      return this.MilestonesRest.milestones().save(milestones,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    milestoneCreate(milestone = {}, callback = angular.noop) {
      return this.MilestonesRest.milestone().save(milestone,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    milestoneGet(milestone = {}, callback = angular.noop) {
      return this.MilestonesRest.milestone().get(milestone,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    milestoneUpdate(milestone = {}, callback = angular.noop) {
      return this.MilestonesRest.milestone().update(milestone,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    pushNode(milestone = {}, callback = angular.noop) {
      return this.MilestonesRest.pushNode().save(milestone,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    popNode(milestone = {}, callback = angular.noop) {
      return this.MilestonesRest.popNode().save(milestone,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Milestones
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Milestones', Milestones);
}());
